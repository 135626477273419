import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import "bootstrap/dist/css/bootstrap.css";
import Welcome from "./components/Welcome/Welcome";
import Sections from "./components/Sections/Sections";
import Card from "./components/Card/Card";

function App() {
  const [showCard, setShowCard] = React.useState<boolean>(false);

  const showCardHandler = (value: boolean) => {
    setShowCard(value);
  };
  return (
    <div className="App">
      {!showCard ? (
        <>
          <Header />
          <Welcome showCard={showCardHandler} />
          <Sections />
        </>
      ) : (
        <Card showCard={showCardHandler} />
      )}
    </div>
  );
}

export default App;
