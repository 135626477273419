export type projectData = {
  title: string;
  position?: string;
  description: string[];
  url: string;
  img: string;
  tech: string[];
};
export type workData = {
  title: string;
  company: string;
  date: string;
  description: string[];
};
export type extrasData = {
  title: string;
  club: string;
  date: string;
  description: string[];
};
export type educationData = {
  title: string;
  school: string;
  date: string;
  courses: string[];
  accomplishments: string[];
};
export enum PanelTypes {
  WORK = "work",
  EDUCATION = "education",
  PROJECT = "project",
  EXTRAS = "extras",
}
export interface IPanelProps {
  type: PanelTypes;
  data: educationData | projectData | workData | extrasData;
}
