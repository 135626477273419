import React from "react";
import Container from "react-bootstrap/Container";
import "./welcome.css";

interface IWelcome {
  showCard: (value: boolean) => void;
}

const Welcome = (props: IWelcome) => {
  const { showCard } = props;
  const welcomePageRef = React.useRef<HTMLDivElement>(null);
  const [lastTapTime, setLastTapTime] = React.useState<number>(0);

  const handleTouchEnd = () => {
    const currentTime = new Date().getTime();
    const tapTimeDiff = currentTime - lastTapTime;

    if (tapTimeDiff < 300 && welcomePageRef?.current) {
      // Double click detected
      if (welcomePageRef.current.clientWidth <= 767) {
        showCard(true);
      }
    }
    setLastTapTime(currentTime);
  };
  return (
    <Container
      fluid
      ref={welcomePageRef}
      className="welcome-page"
      onTouchEnd={handleTouchEnd}
    >
      <h1 className="welcome-title">Ramy Attalla</h1>
      <h2 className="welcome-sub-title">Software Developer</h2>
    </Container>
  );
};

export default Welcome;
