import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import linkedinLogo from "../../assets/logos/linkedin_logo.png";
import emailLogo from "../../assets/logos/email_logo.png";
import resumeLogo from "../../assets/logos/resume_logo.png";
import ContactForm from "./Contact-form";
import resume from "../../assets/RamyAttallaResume.pdf";
import emailjs from "emailjs-com"; // eslint-disable-line @typescript-eslint/no-unused-vars
import githubLogo from "../../assets/logos/github_logo.png";

const Contact = () => {
  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (e.target) {
      // SUBMIT REAL REQUEST
      emailjs
        .sendForm(
          "porfolio",
          "template_xbvmsar",
          e.target, //e.target
          "3RBI79_MAPSrmXKuU"
        )
        .then(
          (result) => {
            e.target.reset();
            setSubmitted(true);
            setTimeout(() => {
              setSubmitted(false);
            }, 4000);
          },
          (error) => {
            alert(
              "Some error has occurred! Please try sending a direct email instead :)"
            );
          }
        );
    }
  };
  return (
    <Container fluid className="contact-me-section">
      <Row>
        <Col xl="5">
          <div className="logos">
            <a
              href="https://www.linkedin.com/in/ramy-attalla/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={linkedinLogo}
                alt="linkedin profile"
                title="Linkedin Profile"
              />
            </a>
            <a
              href="https://github.com/ramy-att/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={githubLogo}
                alt="GitHub profile"
                title="GitHub Profile"
              />
            </a>
            <img
              src={emailLogo}
              alt="email address"
              title="Send email"
              onClick={() =>
                (window.location.href = "mailto:ramysamerattalla@gmail.com")
              }
            />
            <a
              href={resume}
              title="Download Resume"
              download="Ramy_Attalla_Resume"
              rel="noreferrer"
              target="_blank"
            >
              <img src={resumeLogo} alt="resume download" />
            </a>
          </div>
        </Col>
        <Col>
          <ContactForm submitted={submitted} onSubmit={submitHandler} />
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
