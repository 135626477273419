import React from "react";
import educationInfo from "../../data/education.json";
import workInfo from "../../data/work.json";
import extrasInfo from "../../data/extras.json";
import projectInfo from "../../data/projects.json";
import "./sections.css";
import Panel from "../Panel/Panel";
import {
  PanelTypes,
  workData,
  projectData,
  educationData,
  extrasData,
} from "../Panel/IPanel";
import Contact from "../Contact/Contact";
import About from "../About/About";

const Sections = () => {
  return (
    <div className="sections">
      <div className="section">
        <h1 id="about" className="section-title">
          About Me
        </h1>
        <About />
      </div>
      <div className="section">
        <h1 id="education" className="section-title">
          Education
        </h1>
        {educationInfo.map((school) => (
          <React.Fragment key={school.title}>
            <Panel type={PanelTypes.EDUCATION} data={school as educationData} />
          </React.Fragment>
        ))}
      </div>
      <div className="section">
        <h1 id="work" className="section-title">
          Work
        </h1>
        {workInfo.map((work) => (
          <Panel
            key={work.title}
            type={PanelTypes.WORK}
            data={work as workData}
          />
        ))}
      </div>
      <div className="section">
        <h1 id="work" className="section-title">
          Leadership & Extracurriculars
        </h1>
        {extrasInfo.map((club) => (
          <Panel
            key={club.title}
            type={PanelTypes.EXTRAS}
            data={club as extrasData}
          />
        ))}
      </div>
      <div className="section">
        <h1 id="projects" className="section-title">
          Projects
        </h1>
        <div className="projects">
          {projectInfo.map((project) => (
            <Panel
              key={project.title}
              type={PanelTypes.PROJECT}
              data={project as projectData}
            />
          ))}
        </div>
      </div>
      <div className="section">
        <h1 id="contact" className="section-title">
          Contact Me
        </h1>
        <Contact />
      </div>
    </div>
  );
};
export default Sections;
