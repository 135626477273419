import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="about-me">
      <p>
        Software engineering student with experience in web development, software design, UX design & machine
        learning!
      </p>
      <p>Software Developer Intern @ Ericsson Canada. Currently open to freelancing.</p>
    </div>
  );
};

export default About;
