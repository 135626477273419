export const seeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="currentColor"
    className="bi bi-eye"
    viewBox="0 0 16 16"
  >
    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
  </svg>
);
export const infoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="white"
    className="bi bi-card-text readMore"
    viewBox="0 0 16 16"
  >
    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
    <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
  </svg>
);

export const sendIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="bi bi-send send-icon"
    viewBox="0 0 16 16"
  >
    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
  </svg>
);
export const sentIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="bi bi-send-check checkmark"
    viewBox="0 0 16 16"
  >
    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z" />
    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
  </svg>
);

export const telephone = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-telephone"
    viewBox="0 0 16 16"
    iconname="telephone"
  >
    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
  </svg>
);

export const website = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="128"
    height="128"
    viewBox="0 0 128 128"
    iconname="website"
  >
    <path d="M114,15.25H14A9.761,9.761,0,0,0,4.25,25v77A10.762,10.762,0,0,0,15,112.75h98A10.762,10.762,0,0,0,123.75,102V25A9.761,9.761,0,0,0,114,15.25ZM120.25,25V36.683H52.888L61.37,18.75H114A6.257,6.257,0,0,1,120.25,25ZM14,18.75H57.5L49.016,36.683H7.75V25A6.257,6.257,0,0,1,14,18.75Zm99,90.5H15A7.258,7.258,0,0,1,7.75,102V40.183h112.5V102A7.258,7.258,0,0,1,113,109.25Z" />
    <path d="M21.57,33.467a5.75,5.75,0,1,0-5.75-5.75A5.756,5.756,0,0,0,21.57,33.467Zm0-8a2.25,2.25,0,1,1-2.25,2.25A2.253,2.253,0,0,1,21.57,25.467Z" />
    <path d="M37.626,33.467a5.75,5.75,0,1,0-5.75-5.75A5.756,5.756,0,0,0,37.626,33.467Zm0-8a2.25,2.25,0,1,1-2.25,2.25A2.253,2.253,0,0,1,37.626,25.467Z" />
    <path d="M67.522,29.467h44.745a1.75,1.75,0,0,0,0-3.5H67.522a1.75,1.75,0,0,0,0,3.5Z" />
    <path d="M110.067,50.967H64a1.75,1.75,0,0,0-1.75,1.75v11A1.75,1.75,0,0,0,64,65.467h46.067a1.749,1.749,0,0,0,1.75-1.75v-11A1.749,1.749,0,0,0,110.067,50.967Zm-1.75,11H65.75v-7.5h42.567Z" />
    <path d="M110.067,72.967H64a1.75,1.75,0,1,0,0,3.5h46.067a1.75,1.75,0,0,0,0-3.5Z" />
    <path d="M110.067,83.967H64a1.75,1.75,0,1,0,0,3.5h46.067a1.75,1.75,0,0,0,0-3.5Z" />
    <path d="M110.067,94.967H64a1.75,1.75,0,1,0,0,3.5h46.067a1.75,1.75,0,0,0,0-3.5Z" />
    <path d="M34.9,75.209a8.478,8.478,0,1,0-8.477-8.478A8.487,8.487,0,0,0,34.9,75.209Zm0-13.455a4.978,4.978,0,1,1-4.977,4.977A4.982,4.982,0,0,1,34.9,61.754Z" />
    <path d="M53.615,87.29V52.717a1.749,1.749,0,0,0-1.75-1.75H17.933a1.749,1.749,0,0,0-1.75,1.75v44a1.749,1.749,0,0,0,1.75,1.75H51.865a1.749,1.749,0,0,0,1.75-1.75V87.349A.581.581,0,0,0,53.615,87.29Zm-3.5-32.823v29.52A29.2,29.2,0,0,0,34.9,79.94a29.2,29.2,0,0,0-15.217,4.048V54.467Zm-30.432,40.5V88.256c3.8-3.058,9.283-4.816,15.217-4.816S46.314,85.2,50.115,88.254v6.713Z" />
  </svg>
);
export const email = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-envelope"
    viewBox="0 0 16 16"
    iconname="email"
  >
    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
  </svg>
);
