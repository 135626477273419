import React from "react";
import { Form, Button } from "react-bootstrap";
import { sendIcon, sentIcon } from "../../assets/icons/icons";
import "./contact.css";

interface IContactForm {
  onSubmit: (e: any) => void;
  submitted: boolean;
}

const ContactForm = (props: IContactForm) => {
  const { onSubmit, submitted } = props;

  const formRef = React.useRef(null);

  return (
    <div className="contact-form">
      {!submitted ? (
        <Form ref={formRef} onSubmit={onSubmit}>
          <Form.Group className="form-group mb-3" controlId="name-input">
            <Form.Label className="form-label">Name</Form.Label>
            <Form.Control
              className="form-control"
              required
              name="name"
              placeholder="Oliver Queen"
            />
          </Form.Group>
          <Form.Group className="form-group mb-3" controlId="email-input">
            <Form.Label className="form-label">Email address</Form.Label>
            <Form.Control
              className="form-control"
              required
              name="email"
              type="email"
              placeholder="oliver@queen.com"
            />
          </Form.Group>
          <Form.Group className="form-group mb-3" controlId="message-input">
            <Form.Label className="form-label">Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              className="form-control"
              required
              name="message"
              placeholder="I'm messaging you regarding a billion dollar software!"
            />
          </Form.Group>
          <Button className="submit-button" type="submit">
            Hit me up! {sendIcon}
          </Button>
        </Form>
      ) : (
        <div className="success_message">
          <h1>Thank you for messaging me! {sentIcon} </h1>
          <h2>I'll get back to you asap!</h2>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
