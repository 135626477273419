import "./card.css";
import { website, email, telephone } from "../../assets/icons/icons";
import { useState } from "react";
import qrCode from "../../assets/logos/qr_code.png";

interface ICard {
  showCard: (value: boolean) => void;
}

const Card = (props: ICard) => {
  const { showCard } = props;
  const [lastTapTime, setLastTapTime] = useState(0);

  const handleTouchEnd = () => {
    const currentTime = new Date().getTime();
    const tapTimeDiff = currentTime - lastTapTime;

    if (tapTimeDiff < 300) {
      // Double click detected
      showCard(false);
    }

    setLastTapTime(currentTime);
  };

  return (
    <div className="card">
      <div className="first-section" onTouchEnd={handleTouchEnd}>
        <div className="logo">R</div>
        <h1 className="name">Ramy Attalla</h1>
        <hr />
      </div>
      <div className="my-info">
        <p>
          {telephone} <a href="tel:+1438-499-1203">+1 438-499-1203</a>
        </p>
        <p
          onClick={() =>
            (window.location.href = "mailto:ramysamerattalla@gmail.com")
          }
        >
          {email} ramysamerattalla@gmail.com
        </p>
        <p>
          {website}
          <a href="https://ramy-attalla.com">https://ramy-attalla.com</a>
        </p>
      </div>
      <img className="qr-code" alt="Website QR code" src={qrCode} />
    </div>
  );
};
export default Card;
