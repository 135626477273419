import React from "react";
import "./panel.css";
import { seeIcon, infoIcon } from "../../assets/icons/icons";
import {
  IPanelProps,
  educationData,
  projectData,
  workData,
  PanelTypes,
  extrasData,
} from "./IPanel";
import airplane from "../../assets/airplane.png";

const Panel = (props: IPanelProps) => {
  const { type } = props;
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [showPlane, setShowPlane] = React.useState<boolean>(false);
  const [hovering, setHovering] = React.useState<boolean>(false);
  const extrasPanelRef = React.useRef<HTMLDivElement>(null);

  const renderEduPanel = () => {
    const data = props.data as educationData;
    return (
      <div
        className={`panel ${showDetails ? "showDetails" : ""}`}
        onClick={() => {
          setShowDetails((prev) => !prev);
        }}
      >
        {!showDetails ? (
          <>
            <h2 className="sub-title">{data.title}</h2>
            <h1 className="title">{data.school}</h1>
            <h2 className="sub-title">{data.date}</h2>
          </>
        ) : (
          <>
            <h2 className="sub-title">
              <span>Accomplishments:</span> {data.accomplishments.join(", ")}
            </h2>
            <h2 className="sub-title">
              <span>Courses:</span> {data.courses.join(", ")}
            </h2>
          </>
        )}
      </div>
    );
  };
  const renderWorkPanel = () => {
    const data = props.data as workData;
    return (
      <div
        className={`panel work ${showDetails ? "showDetails" : ""}`}
        onClick={() => {
          setShowDetails((prev) => !prev);
        }}
      >
        {!showDetails ? (
          <>
            <h2 className="sub-title">{data.title}</h2>
            <h1 className="title">{data.company}</h1>
            <h2 className="sub-title">{data.date}</h2>
          </>
        ) : (
          <ul>
            {data.description.map((bulletPoint: string, idx: number) => (
              <li key={idx} className="bullet-point-description">
                {bulletPoint}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  const renderExtrasPanel = () => {
    const data = props.data as extrasData;
    return (
      <div
        ref={extrasPanelRef}
        className={`panel clubs ${showDetails ? "showDetails" : ""}`}
        onClick={() => {
          if (!showDetails) {
            setShowPlane(true);
            setTimeout(() => {
              setShowPlane(false);
            }, 990);
          }
          setShowDetails((prev) => !prev);
        }}
      >
        {showPlane && (
          <img className="airplane-img" src={airplane} alt="Airplane Flying" />
        )}
        {!showDetails ? (
          <>
            <h2 className="sub-title">{data.title}</h2>
            <h1 className="title">{data.club}</h1>
            <h2 className="sub-title">{data.date}</h2>
          </>
        ) : (
          <ul>
            {data.description.map((bulletPoint: string, idx: number) => (
              <li key={idx} className="bullet-point-description">
                {bulletPoint}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  const renderProjPanel = () => {
    const data = props.data as projectData;

    const image = require(`../../assets/thumbnails/${data.img}`);
    const clickHandler = (e: any) => {
      const ev = e.target as HTMLElement;
      if (!showDetails && !ev.closest(".readMore")) window.open(data.url);
    };
    const hoverHandler = (e: any) => {
      const ev = e.target as HTMLElement;
      if (
        !showDetails &&
        !ev.classList.contains("readMore") &&
        !ev.parentElement?.classList.contains("readMore")
      )
        setHovering(true);
    };
    return (
      <div
        className={`projectPanel ${hovering ? "hovering" : ""}`}
        onClick={clickHandler}
        onMouseOver={hoverHandler}
        onMouseLeave={() => setHovering(false)}
      >
        {!showDetails ? (
          <>
            <a href={data.url} target="_blank" rel="noreferrer">
              <img
                src={image}
                alt={data.title}
                title={data.title}
                className={`project-image ${hovering ? "hovering" : ""}`}
              />
            </a>
            <div className="panel-footer">
              <span className="footer-title">{data.title}</span>
              <div className="footer-actions">
                <span
                  onClick={() => setShowDetails(true)}
                  title="Read more"
                  className="readMore"
                  onMouseOver={() => setHovering(false)}
                >
                  {infoIcon}
                </span>
                <span title="Check it out!">{seeIcon}</span>
              </div>
            </div>
          </>
        ) : (
          <div
            className="project-details"
            onClick={() => setShowDetails(false)}
          >
            <div className="project-info">
              <ul>
                {data?.position && <li>Acted as: {data.position}</li>}
                {data.description.map((desc, idx) => (
                  <li key={idx}>{desc}</li>
                ))}
              </ul>
            </div>
            <div className="tags-container">
              {data.tech.map((tech: string) => (
                <span className="tag" key={tech}>
                  {tech}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };
  return type === PanelTypes.EDUCATION
    ? renderEduPanel()
    : type === PanelTypes.WORK
    ? renderWorkPanel()
    : type === PanelTypes.EXTRAS
    ? renderExtrasPanel()
    : renderProjPanel();
};

export default Panel;
